/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
// import pako from 'pako';
import * as CONST from 'common/const';
import XLSXUtils from 'utils/xlsxUtils';
import { transformExcelToJson, journalToJson } from 'utils/transformers';
import api from 'utils/api';
import AWS from 'aws-sdk';
import config from 'config/config.json';
import { ensureAuthenticated } from 'utils/auth';
import {fetchDataFailure, postFileSuccess} from './validationUI';
import CONSTANT from '../utils/constant';

const UserPoolId = process.env.USER_POOL_ID;
const IdentityPoolId = process.env.IDENTITY_POOL_ID;
const S3_BUCKET = process.env.ICTP_MJE_UPLOADS_S3_BUCKET;
const base64js = require('base64-js');

function uploadToS3(uploadName, file, jwtToken) {
  const region = 'us-west-2';
  AWS.config.update({
    region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId,
      Logins: {
        [`cognito-idp.us-west-2.amazonaws.com/${UserPoolId}`]: jwtToken,
      },
    }),
  });

  const s3 = new AWS.S3({
    region,
  });

  // debugger;
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: uploadName,
      Body: file,
      ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx Content Type
    };

    s3.upload(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export const uploadFileStart = () => ({
  type: CONST.LANDING_PAGE_UPLOAD_FILE_START,
});

export const uploadFileSuccess = mjeData => ({
  type: CONST.LANDING_PAGE_UPLOAD_FILE_SUCCESS,
  payload: mjeData,
});

export const uploadFileFailure = (error = null) => ({
  type: CONST.LANDING_PAGE_UPLOAD_FILE_FAILURE,
  globalError: error,
});

function checkFileValidity(file) {
  if (file.size > CONSTANT.MAX_FILE_SIZE_ALLOWED) {
    return CONSTANT.MAX_FILE_SIZE_EXCEEDED_MESSAGE;
  }

  let filetypeIsInvalid = true;

  for (let i = 0; i < CONSTANT.ACCEPTED_FILE_TYPES.length; i++) {
    if (file.name.toLowerCase().endsWith(CONSTANT.ACCEPTED_FILE_TYPES[i])) {
      filetypeIsInvalid = false;
    }
  }

  if (filetypeIsInvalid) {
    return CONSTANT.UNSUPPORTED_FILE_TYPE_MESSAGE.concat(CONSTANT.ACCEPTED_FILE_TYPES.join(", "), '.');
  }

  return null;
}

export const uploadFile = file => async (dispatch, getState) => {
  const errorMsg = checkFileValidity(file);
  if (errorMsg != null) {
    dispatch(uploadFileFailure(new Error(errorMsg)));
    throw new Error(errorMsg);
  }

  dispatch(uploadFileStart());
  const { username } = getState().auth;
  const uploadName = `${new Date().getTime()}_${username}_${file.name}`;
  const payload = {
    key: uploadName,
    name: file.name,
    createUser: username,
  };

  const auth = await ensureAuthenticated();
  const session = auth.getSignInUserSession();
  const jwtToken = session.getIdToken().getJwtToken();

  // First upload the file to S3
  // Then parse the S3 file and store the uploaded data
  const workbook_id = await uploadToS3(uploadName, file, jwtToken)
    .then(() => api.uploadMJEFile(payload))
    .then(uploadResult => uploadResult.workbook_id)
    .catch((err) => {
      dispatch(uploadFileFailure(err));
      throw new Error(err);
    });

  // Then trigger the MJE and IPE workflow
  return api.runWorkflowForExpressUpload(workbook_id)
    .then(({ status, workbookIsValid }) => {
      if (status === CONSTANT.WORKFLOW_STATUS.FAILED) {
        const err = CONSTANT.WORKFLOW_FAILURE_ERROR_MESSAGE;
        dispatch(uploadFileFailure(err));
        throw new Error(err);
      }
      if (status === CONSTANT.WORKFLOW_STATUS.TIMEOUT) {
        const err = CONSTANT.WORKFLOW_TIMEOUT_ERROR_MESSAGE;
        dispatch(uploadFileFailure(err));
        throw new Error(err);
      }

      return api.getIndirectTaxSummary(workbook_id)
        .then((indirectTaxSummary) => {
          // Both input and tax lines have finished validations and tax lines were generated;
          // Hence, the Validation UI should display tax lines as well.
          const displayTaxLines = (status === CONSTANT.WORKFLOW_STATUS.FINISHED) && !indirectTaxSummary.noTaxAutomation;
          dispatch(uploadFileSuccess({ workbook_id }));
          return { workbook_id, valid: workbookIsValid, displayTaxLines };
        }).catch((err) => {
          dispatch(uploadFileFailure(err));
          throw new Error(err);
        });
    })
    .catch((err) => {
      dispatch(uploadFileFailure(err));
      throw new Error(err);
    });
};

export const uploadFileStepByStep = file => async (dispatch, getState) => {
  const errorMsg = checkFileValidity(file);
  if (errorMsg != null) {
    dispatch(uploadFileFailure(new Error(errorMsg)));
    throw new Error(errorMsg);
  }

  dispatch(uploadFileStart());
  const { username } = getState().auth;
  const uploadName = `${new Date().getTime()}_${username}_${file.name}`;
  const payload = {
    key: uploadName,
    name: file.name,
    createUser: username,
  };

  const auth = await ensureAuthenticated();
  const session = auth.getSignInUserSession();
  const jwtToken = session.getIdToken().getJwtToken();

  // First upload the file to S3
  // Then parse the S3 file and store the uploaded data
  const workbook_id = await uploadToS3(uploadName, file, jwtToken)
    .then(() => api.uploadMJEFile(payload))
    .then(uploadResult => uploadResult.workbook_id)
    .catch((err) => {
      dispatch(uploadFileFailure(err));
      throw new Error(err);
    });

  // Then trigger the MJE workflow
  return api.runMJEWorkflow(workbook_id)
    .then(({ status }) => {
      if (status === CONSTANT.WORKFLOW_STATUS.FAILED) {
        const err = CONSTANT.WORKFLOW_FAILURE_ERROR_MESSAGE;
        dispatch(uploadFileFailure(err));
        throw new Error(err);
      }
      if (status === CONSTANT.WORKFLOW_STATUS.TIMEOUT) {
        const err = CONSTANT.WORKFLOW_TIMEOUT_ERROR_MESSAGE;
        dispatch(uploadFileFailure(err));
        throw new Error(err);
      }
      dispatch(uploadFileSuccess({ workbook_id }));
      return ({ workbook_id });
    })
    .catch((err) => {
      dispatch(uploadFileFailure(err));
      throw new Error(err);
    });
};

export const searchStart = () => ({
  type: CONST.LANDING_PAGE_SEARCH_START,
});

export const searchSuccess = (items = []) => ({
  type: CONST.LANDING_PAGE_SEARCH_SUCCESS,
  payload: items,
});

export const searchFailure = (error = null) => ({
  type: CONST.LANDING_PAGE_SEARCH_FAILURE,
  globalError: error,
});

const getDefaultDashboardPayload = (username) => {
  // Set the start date to 60 days in the past
  const startDate = new Date(Date.now() - 60 * 24 * 60 * 60 * 1000);
  // Set the end date to the last day of the next month at the end of the day
  const today = new Date();
  const endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
  endDate.setUTCHours(23,59,59,999);
  const padWithZero = value => (value < 10 ? `0${value}` : `${value}`);

  return [
    {
      attribute: 'prepare_user',
      value: username,
      attributeType: 'HEADER',
    },
    {
      attribute: 'accounting_date',
      start: `${startDate.getFullYear()}-${padWithZero(startDate.getMonth() + 1)}-${padWithZero(
        startDate.getDate(),
      )}`,
      end: `${endDate.getFullYear()}-${padWithZero(endDate.getMonth() + 1)}-${padWithZero(
        endDate.getDate(),
      )}`,
      dateRange: true,
      attributeType: 'HEADER',
    },
  ];
};

export const getSearchPayload = (searchItems, accountingStartDate, accountingEndDate, dynamicAttribute, dynamicAttributeValue, primaryAttributes) => {
  const searchPayload = [];
  let searchParam;

  primaryAttributes.forEach((item) => {
    if (!item.dateRange) {
      if (searchItems[item.id] != null && searchItems[item.id] !== '') {
        searchParam = {
          attribute: item.id,
          value: searchItems[item.id],
          attributeType: item.attributeType,
          isDynamic: false,
        };
        searchPayload.push(searchParam);
      }
    }
  });

  if (dynamicAttribute.id !== 'none' && dynamicAttributeValue !== '') {
    searchParam = {
      attribute: dynamicAttribute.id,
      value: dynamicAttributeValue,
      attributeType: dynamicAttribute.attributeType,
      isDynamic: true,
    };
    searchPayload.push(searchParam);
  }

  searchParam = {
    attribute: 'accounting_date',
    start: accountingStartDate,
    end: accountingEndDate,
    dateRange: true,
    attributeType: 'HEADER',
  };

  searchPayload.push(searchParam);
  return searchPayload;
};

export const myMJEDashboardSearch = parameters => async (dispatch, getState) => {
  const { username } = getState().auth;
  dispatch(searchStart());
  try {
    const result = await api.searchForMJEDashboard(
      parameters ? { parameters } : { parameters: getDefaultDashboardPayload(username) },
    );
    dispatch(searchSuccess(result.items));
  } catch (e) {
    dispatch(searchFailure(e));
  }
};

export const downloadTemplateStart = () => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_TEMPLATE_START,
});

export const downloadTemplateSuccess = (url = null) => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_TEMPLATE_SUCCESS,
  payload: url,
});

export const downloadTemplateFailure = (error = null) => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_TEMPLATE_FAILURE,
  globalError: error,
});

export const templateReset = () => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_TEMPLATE_RESET,
});

export const downloadTemplate = () => async (dispatch) => {
  dispatch(downloadTemplateStart());
  try {
    const result = await api.downloadTemplate();
    dispatch(downloadTemplateSuccess(result.downloadingUrl));
  } catch (e) {
    dispatch(downloadTemplateFailure(e));
  }
};

export const downloadTemplateReset = () => async (dispatch) => {
  dispatch(templateReset());
};

export const copyFileStart = () => ({
  type: CONST.LANDING_PAGE_COPY_FILE_START,
});

export const copyFileSuccess = (payload = null) => ({
  type: CONST.LANDING_PAGE_COPY_FILE_SUCCESS,
  payload,
});

export const copyFileFailure = (error = null) => ({
  type: CONST.LANDING_PAGE_COPY_FILE_FAILURE,
  globalError: error,
});

export const copyFile = payload => async (dispatch /* , getState */) => {
  dispatch(copyFileStart());
  try {
    const result = await api.copyFile(payload);
    dispatch(copyFileSuccess(result));
  } catch (e) {
    dispatch(copyFileFailure(e));
  }
};

export const resetCopiedFile = () => ({
  type: CONST.LANDING_PAGE_COPY_FILE_RESET,
});

export const deleteWorkbookStart = () => ({
  type: CONST.LANDING_PAGE_DELETE_WORKBOOK_START,
});

export const deleteWorkbookSuccess = (payload = null) => ({
  type: CONST.LANDING_PAGE_DELETE_WORKBOOK_SUCCESS,
  payload,
});

export const deleteWorkbookFailure = (error = null) => ({
  type: CONST.LANDING_PAGE_DELETE_WORKBOOK_FAILURE,
  globalError: error,
});

export const deleteWorkbook = payload => async (dispatch, getState) => {
  dispatch(deleteWorkbookStart());
  try {
    const result = await api.deleteWorkbook(payload);
    dispatch(deleteWorkbookSuccess(result));
    dispatch(myMJEDashboardSearch(null));
  } catch (e) {
    dispatch(deleteWorkbookFailure(e));
  }
};

export const downloadWorkbookStart = () => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_WORKBOOK_START,
});

export const downloadWorkbookSuccess = (data = {}) => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_WORKBOOK_SUCCESS,
  payload: data,
});

export const downloadWorkbookFailure = (error = null) => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_WORKBOOK_FAILURE,
  globalError: error,
});

export const downloadWorkbookReset = () => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_WORKBOOK_RESET,
});

export const downloadWorkbook = payload => async (dispatch) => {
  dispatch(downloadWorkbookStart());
  try {
    const result = await api.downloadDataGrid(payload.requestType, payload.requestId);
    dispatch(downloadWorkbookSuccess(result.workbookURL));
  } catch (e) {
    dispatch(downloadWorkbookFailure(e));
  }
};

export const expressUploadPostFileStart = () => ({
  type: CONST.LANDING_PAGE_POST_FILE_START,
});

export const expressUploadPostFileSuccess = () => ({
  type: CONST.LANDING_PAGE_POST_FILE_SUCCESS,
});

export const expressUploadPostFileFailure = (error = null) => ({
  type: CONST.LANDING_PAGE_POST_FILE_FAILURE,
  globalError: error,
});

export const expressUploadPostFile = workbook_id => async (dispatch, getState) => {
  dispatch(expressUploadPostFileStart());
  try {
    const response = await api.postFile({
      userName: getState().auth.username,
      requestType: 'workbook',
      requestID: workbook_id,
    });
    dispatch(postFileSuccess(response));
    dispatch(expressUploadPostFileSuccess());
  } catch (e) {
    dispatch(expressUploadPostFileFailure(e));
  }
};


export const downloadInvoiceStart = () => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_INVOICE_START,
});

export const downloadInvoiceSuccess = (data = {}) => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_INVOICE_SUCCESS,
  payload: data,
});

export const downloadInvoiceFailure = (error = null) => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_INVOICE_FAILURE,
  globalError: error,
});

export const downloadInvoiceReset = () => ({
  type: CONST.LANDING_PAGE_DOWNLOAD_INVOICE_RESET,
});

export const downloadInvoice = payload => async (dispatch) => {
  console.log({ payload });
  if (payload.invoice_header_id == null && payload.invoice_failure_reason != null) {
    dispatch(downloadInvoiceFailure({ message: `Journal Invoice could not be generated: ${payload.invoice_failure_reason}` }));
    return;
  }
  dispatch(downloadInvoiceStart());
  try {
    const result = await api.downloadInvoice(payload.invoice_header_id, payload.invoice_number);
    if (result.invoiceDocumentBase64) {
      const bytes = base64js.toByteArray(result.invoiceDocumentBase64);
      dispatch(downloadInvoiceSuccess({ fileBytes: bytes, fileName: `${payload.invoice_header_id}.pdf` }));
    } else {
      dispatch(downloadInvoiceFailure({ message: `${result.errorCode}: ${result.errorMessage}` }));
    }
  } catch (e) {
    dispatch(downloadInvoiceFailure(e));
  }
};
